import React, {
    useState,
    useEffect,
} from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import { useTranslation } from 'react-i18next'
import { Wrapper } from '@googlemaps/react-wrapper'

// Moment.js and locales
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import 'moment/dist/locale/cs'
import 'moment/dist/locale/de'
import 'moment/dist/locale/pl'
import type { Moment } from 'moment'

import type {
    SearchType,
    TimeHighlight,
} from '../types'
import {
    START_DATE,
    END_DATE,
} from '../constants'
import {
    ArrowForwardIcon,
    SearchIcon,
} from './icons'
import SearchModal from './search-modal'
import PlacesAutocomplete from './places-autocomplete'
import { getSearchParams } from '../helpers'

const {
    paramLocation,
    paramBeginsAtDate,
    paramBeginsAtTime,
    paramEndsAtDate,
    paramEndsAtTime,
} = getSearchParams()

const domNode = document.getElementById('react-garage-detail-search')
const garageAddress = domNode?.getAttribute('garage-address')
const garageId = domNode?.getAttribute('garage-id')

export default function SearchView({
    home,
    garagesMap,
    garageDetail,
}:{
    home?: boolean,
    garagesMap?: boolean,
    garageDetail?: boolean,
}) {

    const { t, i18n } = useTranslation()
    const userLang = i18n.language

    // Set moment locale from i18n, moment locale used by react-dates
    moment.locale(userLang)
    if (userLang === 'cs') {
        moment.updateLocale('cs', {
            months : [
                "leden", "únor", "březen", "duben", "květen", "červen", "červenec",
                "srpen", "září", "říjen", "listopad", "prosinec"
            ]
        })
    }
    moment.tz.setDefault('Europe/Prague')

    const initialSearch: SearchType = {
        startDate: moment(paramBeginsAtDate, ['DD.MM.YYYY', 'YYYY-MM-DD']),
        startTime: paramBeginsAtTime,
        endDate: moment(paramEndsAtDate, ['DD.MM.YYYY', 'YYYY-MM-DD']),
        endTime: paramEndsAtTime,
        location: paramLocation,
        focusedInput: START_DATE,
    }
    
    const [currentSearch, setCurrentSearch] = useState<SearchType>(initialSearch)

    // Animation trigger for time picker upon date selection
    const [timeHighlight, setTimeHighlight] = useState<TimeHighlight>({
        startTime: false,
        endTime: false,
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeHighlight.startTime === true || timeHighlight.endTime === true) {
                setTimeHighlight({
                    startTime: false,
                    endTime: false,
                })
            }
        }, 400)
        return () => clearTimeout(timer)
    }, [timeHighlight.startTime, timeHighlight.endTime])

    const [mobileSearchDialog, setMobileSearchDialog] = useState<boolean>(false)
    const [desktopSearchDialog, setDesktopSearchDialog] = useState<boolean>(false)

    const handleToggleMobileSearchDialog = () => {
        if (mobileSearchDialog) setCurrentSearch(initialSearch)
        setMobileSearchDialog(!mobileSearchDialog)
    }

    const handleToggleDesktopSearchDialog = () => {
        if (desktopSearchDialog) setCurrentSearch(initialSearch)
        setDesktopSearchDialog(!desktopSearchDialog)
    }

    /**
     * Saves content of a textField into the state object
     */
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | null,
        name: string,
    ) => {
        const newValue = event?.target.value
        setCurrentSearch(prevState => (
            {
                ...prevState,
                [name]: newValue,
            }
        ))
    }
    
    /**
     * Saves content of a textField into the state object
     */
    const handleSelect = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
        name: string,
    ) => {
        setCurrentSearch(prevState => (
            {
                ...prevState,
                [name]: newValue,
            }
        ))
    }

    /**
     * Saves date into the state object
     */
    const handleDateChange = (
        {
            startDate,
            endDate,
        }: {
            startDate: Moment | null,
            endDate: Moment | null,
        },
        focusedInput: typeof START_DATE | typeof END_DATE,
    ) => {
        if (focusedInput === START_DATE) {
            setCurrentSearch(prevState => (
                {
                    ...prevState,
                    startDate,
                    endDate: null,
                }
            ))
            setTimeHighlight(prevState => (
                {
                    ...prevState,
                    startTime: true,
                }
            ))
        } else {
            setCurrentSearch(prevState => (
                {
                    ...prevState,
                    startDate,
                    endDate,
                }
            ))
            setTimeHighlight(prevState => (
                {
                    ...prevState,
                    endTime: true,
                }
            ))
        }
    }

    /**
     * Shifts focus between start and end date
     * @param {*} focusedInput
     */
    const handleFocusChange = (focusedInput: typeof START_DATE | typeof END_DATE) => {
        setCurrentSearch(prevState => (
            {
                ...prevState,
                // Force the focusedInput to always be truthy so that dates are always selectable
                focusedInput: !focusedInput ? START_DATE : focusedInput,
            }
        ))
    }

    /**
     * Resets the search
     */
    const handleSearchReset = () => {
        setCurrentSearch(initialSearch)
    }

    const searchLink = `/${userLang}/${t('routes.garages')
        }?booking-time-from=${currentSearch.startTime ?? ''
        }&booking-time-until=${currentSearch.endTime ?? ''
        }&map_search%5Blocation%5D=${currentSearch.location ?? ''
        }&map_search%5Bbegins_at_date%5D=${currentSearch.startDate?.format('YYYY-MM-DD') ?? ''
        }&map_search%5Bbegins_at_time%5D=${currentSearch.startTime ?? ''
        }&map_search%5Bends_at_date%5D=${currentSearch.endDate?.format('YYYY-MM-DD') ?? ''
        }&map_search%5Bends_at_time%5D=${currentSearch.endTime ?? ''}`
    
    const reservationLink = `/${userLang}/${t('routes.reservations')}/${t('routes.reservations_steps.reservation_time')
        }?reservation_step_reservation_time%5Bbegins_at_date%5D=${
        currentSearch.startDate?.format('YYYY-MM-DD') ?? ''}&reservation_step_reservation_time%5Bbegins_at_time%5D=${
        currentSearch.startTime ?? ''}&reservation_step_reservation_time%5Bends_at_date%5D=${
        currentSearch.endDate?.format('YYYY-MM-DD') ?? ''}&reservation_step_reservation_time%5Bends_at_time%5D=${
        currentSearch.endTime ?? ''}&reservation_step_reservation_time%5Bgarage_id%5D=${
        garageId}&reservation_step_reservation_time%5Blocation%5D=${
        currentSearch.location ?? ''}`
    
    const formattedStartDate = currentSearch.startDate ? moment(currentSearch.startDate).format('YYYY-MM-DD')+'T'+currentSearch.startTime : null
    const formattedEndDate = currentSearch.endDate ? moment(currentSearch.endDate).format('YYYY-MM-DD')+'T'+currentSearch.endTime : null
    let reservationDays: number
    let reservationHours: number
    const differenceInHours = moment(formattedEndDate).startOf('hour').diff(moment(formattedStartDate).startOf('hour'), 'hours')
    if (differenceInHours > 24) {
        reservationDays = Math.floor(differenceInHours/24)
        reservationHours = differenceInHours%24
    } else {
        reservationDays = 0
        reservationHours = differenceInHours
    }

    const currentDateTime = moment()
    const currentCETDateTime = currentDateTime.clone().tz("Europe/Prague").subtract({hours: 6})
    const selectedCETDateTime = moment(currentSearch.startDate?.format('YYYY-MM-DD')+' '+currentSearch.startTime)

    const renderAutocompletePlaceholder = (): React.ReactElement => {
        return (
            <Input
                value={currentSearch.location ?? ''}
                placeholder={t('search.wherePlaceholder')}
                onChange={(e) => handleChange(e, 'location')}
                name="location"
                sx={{
                    fontSize: '1rem',
                    minHeight: 0,
                    paddingLeft: '0.25rem',
                    paddingRight: '0.25rem',
                    borderColor: 'transparent',
                    borderRadius: '0.25rem',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    lineHeight: '28px',
                    '--Input-focusedHighlight': 'var(--joy-palette-secondary-200)',
                    '&:hover:not(.Joy-focused)': {
                        borderColor: 'var(--joy-palette-secondary-200)',
                    }
                }}
            />
        )
    }

    return (
        <>
            <Box sx={{
                ...home ? {
                    display: 'flex',
                    '@media screen and (min-width: 66rem)': {
                        display: 'none',
                    }
                } : {
                    display: {
                        xs: 'flex',
                        md: 'none',
                    },
                },
                ...garageDetail ? {
                    display: 'flex',
                    '@media screen and (min-width: 76.875rem)': {
                        display: 'none',
                    }
                } : {},
            }}>
                {(garagesMap || garageDetail) &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        padding: '0.5rem 1rem 1rem',
                        fontSize: '0.875rem',
                        background: 'white',
                        boxShadow: `var(--joy-shadowRing, 0 0 #000),
                            0.3px 0.8px 1.1px rgba(187 187 187 / 0.11),
                            0.5px 1.3px 1.8px -0.6px rgba(187 187 187 / 0.18),
                            1.1px 2.7px 3.8px -1.2px rgba(187 187 187 / 0.26)`,
                        zIndex: 2,
                        cursor: 'pointer',
                        ...garageDetail ? {
                            padding: '1rem',
                        } : {},
                    }} onClick={handleToggleMobileSearchDialog}>
                        {!garageDetail &&
                            <Box flexGrow={1} textAlign="center" pb={0.5} fontSize={'0.813rem'}>
                                {currentSearch.location ?? t('search.defaultLocation')}
                            </Box>
                        }
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <b>{initialSearch.startDate !== null ? moment(initialSearch.startDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                , {initialSearch.startTime ?? '--:--'}
                            </Box>
                            <ArrowForwardIcon color="#BDBDBD"/>
                            <Box>
                                <b>{initialSearch.endDate !== null ? moment(initialSearch.endDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                , {initialSearch.endTime ?? '--:--'}
                            </Box>
                        </Box>
                    </Box>
                }
                {home &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0.75rem 1rem 0.6rem',
                            background: 'white',
                            borderRadius: '0.25rem',
                            border: '1px solid var(--joy-palette-primary-500)',
                            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.15)`,
                        }}>
                            <Box pr={1}>
                                <SearchIcon color="#BDBDBD" width={32} height={32}/>
                            </Box>
                            <Box flexGrow={1}>
                                <FormControl>
                                    <FormLabel sx={{
                                        fontSize: '0.750rem',
                                        textTransform: 'uppercase',
                                        fontWeight: 600,
                                        color: 'primary.500',
                                        marginBottom: 0,
                                        paddingLeft: '0.25rem',
                                        lineHeight: '18px',
                                    }}>{t('search.where')}</FormLabel>
                                    <Wrapper
                                        // @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
                                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                        version="weekly"
                                        libraries={["places", "marker"]}
                                        render={renderAutocompletePlaceholder}
                                    >
                                        <PlacesAutocomplete
                                            location={currentSearch.location}
                                            handleSelect={handleSelect}
                                            autocompleteType='light'
                                        />
                                    </Wrapper>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '1rem',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            background: 'white',
                            borderRadius: '0.25rem',
                            border: '1px solid var(--joy-palette-primary-500)',
                            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.15)`,
                            '&:hover': {
                                backgroundColor: 'secondary.100'
                            },
                        }} onClick={handleToggleMobileSearchDialog}>
                            <Box sx={{
                                fontSize: {
                                    xs: '0.925rem',
                                    sm: '1rem',
                                },
                                padding: {
                                    xs: '0.75rem',
                                    sm: '0.75rem 1.5rem',
                                },
                            }}>
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationStart')}
                                </Box>
                                <Box>
                                    <b>{initialSearch.startDate !== null ? moment(initialSearch.startDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {initialSearch.startTime ?? '--:--'}
                                </Box>
                            </Box>
                            <ArrowForwardIcon color="#BDBDBD"/>
                            <Box sx={{
                                fontSize: {
                                    xs: '0.925rem',
                                    sm: '1rem',
                                },
                                padding: {
                                    xs: '0.75rem',
                                    sm: '0.75rem 1.5rem',
                                },
                            }}>
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationEnd')}
                                </Box>
                                <Box>
                                    <b>{initialSearch.endDate !== null ? moment(initialSearch.endDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {initialSearch.endTime ?? '--:--'}
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                            <Button
                                variant="solid"
                                component="a"
                                href={searchLink}
                                sx={{
                                    fontSize: '1rem',
                                    width: '100%',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    // imitate disabled for link
                                    ...(!currentSearch.startDate ||
                                        !currentSearch.endDate ||
                                        (reservationDays < 1 && reservationHours < 1) ||
                                        (selectedCETDateTime < currentCETDateTime)) ? {
                                        pointerEvents: 'none',
                                        cursor: 'default',
                                        color: '#fff',
                                        backgroundColor: '#828282',
                                    } : {},
                                    // legacy application stylesheet override
                                    '&:hover': {
                                        color: 'secondary.solidBg',
                                    }
                                }}
                            >
                                {t('search.search')}
                            </Button>
                        </Box>
                    </Box>
                }
                <SearchModal
                    searchModalOpen={mobileSearchDialog}
                    handleToggleSearchModalOpen={handleToggleMobileSearchDialog}
                    currentSearch={currentSearch}
                    modalType="mobile"
                    handleSearchReset={handleSearchReset}
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    handleDateChange={handleDateChange}
                    handleFocusChange={handleFocusChange}
                    garagesMap={garagesMap}
                    reservationDays={reservationDays}
                    reservationHours={reservationHours}
                    selectedCETDateTime={selectedCETDateTime}
                    currentCETDateTime={currentCETDateTime}
                    searchLink={garageDetail ? reservationLink : searchLink}
                    garageDetail={garageDetail}
                    home={home}
                    timeHighlight={timeHighlight}
                />
            </Box>
            {/* Desktop */}
            <Box sx={{
                justifyContent: 'center',
                ...home ? {
                    display: 'none',
                    padding: 0,
                    '@media screen and (min-width: 66rem)': {
                        display: 'flex',
                    }
                } : {
                    display: {
                        xs: 'none',
                        md: 'flex',
                    },
                },
                ...garagesMap ? {
                    padding: '0.5rem',
                } : {},
                ...garageDetail ? {
                    display: 'none',
                    '@media screen and (min-width: 76.875rem)': {
                        display: 'flex',
                    }
                } : {},
            }}>
                {!garageDetail && 
                    <Box sx={{
                        display: 'flex',
                        background: 'white',
                        borderRadius: '0.25rem',
                        border: '1px solid var(--joy-palette-primary-300)',
                        ...home ? {
                            border: '3px solid var(--joy-palette-secondary-300)',
                            boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.15)`,
                        } : {},
                    }}>
                        <Box display="flex" alignItems="center" pr={3} pl={2} py={1.5}>
                            <Box pr={1}>
                                <SearchIcon color="#BDBDBD" width={32} height={32}/>
                            </Box>
                            <Box>
                                <FormControl>
                                    <FormLabel sx={{
                                        fontSize: '0.750rem',
                                        textTransform: 'uppercase',
                                        fontWeight: 600,
                                        color: 'primary.500',
                                        marginBottom: 0,
                                        paddingLeft: '0.25rem',
                                        lineHeight: '18px',
                                    }}>{t('search.where')}</FormLabel>
                                    <Wrapper
                                        // @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
                                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                                        version="weekly"
                                        libraries={["places", "marker"]}
                                        render={renderAutocompletePlaceholder}
                                    >
                                        <PlacesAutocomplete
                                            location={currentSearch.location}
                                            handleSelect={handleSelect}
                                            autocompleteType='light'
                                        />
                                    </Wrapper>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            borderLeft: '1px solid var(--joy-palette-primary-200)',
                            borderRight: '1px solid var(--joy-palette-primary-200)',
                            '&:hover': {
                                backgroundColor: 'secondary.100'
                            },
                            ...(garagesMap && desktopSearchDialog) ? {
                                zIndex: 1400,
                                backgroundColor: 'white',
                            } : {},
                        }} onClick={handleToggleDesktopSearchDialog}>
                            <Box px={3} py={1.5} minWidth="11.25rem">
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationStart')}
                                </Box>
                                <Box>
                                    <b>{currentSearch.startDate !== null ? moment(currentSearch.startDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {currentSearch.startTime ?? '--:--'}
                                </Box>
                            </Box>
                            <ArrowForwardIcon color="#BDBDBD"/>
                            <Box px={3} py={1.5} minWidth="11.25rem">
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationEnd')}
                                </Box>
                                <Box>
                                    <b>{currentSearch.endDate !== null ? moment(currentSearch.endDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {currentSearch.endTime ?? '--:--'}
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" px={2} py={1}>
                            <Button
                                variant="solid"
                                component="a"
                                href={searchLink}
                                sx={{
                                    ...home ? {
                                        fontSize: '1rem',
                                    } : {},
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    // imitate disabled for link
                                    ...(!currentSearch.startDate ||
                                        !currentSearch.endDate ||
                                        (reservationDays < 1 && reservationHours < 1) ||
                                        (selectedCETDateTime < currentCETDateTime)) ? {
                                        pointerEvents: 'none',
                                        cursor: 'default',
                                        color: '#fff',
                                        backgroundColor: '#828282',
                                    } : {},
                                    // legacy application stylesheet override
                                    '&:hover': {
                                        color: 'secondary.solidBg',
                                    }
                                }}
                            >
                                {t('search.search')}
                            </Button>
                        </Box>
                    </Box>
                }
                {garageDetail && 
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'white',
                        width: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0.75rem 0.5rem 0.75rem 1.5rem',
                            borderBottom: '1px solid var(--joy-palette-primary-200)',
                            fontSize: '1.125rem',
                        }}>
                            {garageAddress}
                            <Button
                                variant="plain"
                                onClick={handleToggleDesktopSearchDialog}
                            >
                                {t('search.edit')}
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            flexGrow: 1,
                            justifyContent: 'space-between',
                            padding: '0.75rem 1.5rem',
                            borderBottom: '1px solid var(--joy-palette-primary-200)',
                            '&:hover': {
                                backgroundColor: 'secondary.100'
                            },
                            ...(garagesMap && desktopSearchDialog) ? {
                                zIndex: 1400,
                            } : {},
                        }} onClick={handleToggleDesktopSearchDialog}>
                            <Box pr={2}>
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationStart')}
                                </Box>
                                <Box minWidth="8.25rem">
                                    <b>{initialSearch.startDate !== null ? moment(initialSearch.startDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {initialSearch.startTime ?? '--:--'}
                                </Box>
                            </Box>
                            <ArrowForwardIcon color="#BDBDBD"/>
                            <Box pl={2}>
                                <Box sx={{
                                    fontSize: '0.750rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    color: 'primary.500'
                                }}>
                                    {t('search.reservationEnd')}
                                </Box>
                                <Box minWidth="8.25rem">
                                    <b>{initialSearch.endDate !== null ? moment(initialSearch.endDate).format('DD.MM.YYYY') : '- -. - -. - - - -'}</b>
                                    , {initialSearch.endTime ?? '--:--'}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            padding: '0.75rem 1.5rem',
                        }}>
                            <Box sx={{
                                fontSize: '0.750rem',
                                textTransform: 'uppercase',
                                fontWeight: 600,
                                color: 'primary.500'
                            }}>
                                {t('search.reservationLength')}
                            </Box>
                            <Box>
                                {(reservationDays || reservationHours) ?
                                    <Box color={(reservationDays < 1 && reservationHours < 1) ? 'red' : 'inherit'}>
                                        <span>{reservationDays ? t('search.days', {count: reservationDays}) : ''}</span> 
                                        <span>{reservationHours ? t('search.hours', {count: reservationHours}) : ''}</span>
                                    </Box> 
                                    :
                                    <Box color='red'>{t('search.hours', {count: 0})}</Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                }
                <SearchModal
                    searchModalOpen={desktopSearchDialog}
                    handleToggleSearchModalOpen={handleToggleDesktopSearchDialog}
                    currentSearch={currentSearch}
                    modalType="desktop"
                    handleSearchReset={handleSearchReset}
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    handleDateChange={handleDateChange}
                    handleFocusChange={handleFocusChange}
                    garagesMap={garagesMap}
                    reservationDays={reservationDays}
                    reservationHours={reservationHours}
                    selectedCETDateTime={selectedCETDateTime}
                    currentCETDateTime={currentCETDateTime}
                    searchLink={garageDetail ? reservationLink : searchLink}
                    garageDetail={garageDetail}
                    home={home}
                    timeHighlight={timeHighlight}
                />
            </Box>
        </>
    )
}
